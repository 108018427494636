import { Type } from "class-transformer";
import { AmountTx } from "./bank/AmountTx";
import { CheckInOutModel } from "./CheckInModel";
import { AppointmentStatusModel, AppointmentTypesModel } from "./enums/AppointmentTypesModel";
import { LovedOnePassportModel } from "./LovedOnePersonalInfoModel";
import { TaskModel } from "./taskModel";
import { AppointmentExtensionModel } from "./appointment-extension.model";
import { PenaltyContentModel } from "./bank/PenaltyModel";
import { LoRates } from "./CgRates";
import { CgPaymentCustomLineItemModel } from "./custom-line-item.model";
import { CsBankModel } from "./bank/Bank";
import { CareGiverModel } from "./careGiverModel";

export class AppointmentModel {
  _id: string;
  type: AppointmentTypesModel;
  status: AppointmentStatusModel;
  accepturl: string;
  rejecturl: string;
  communications?: CommunicationModel[];
  initCommunication() {
    if (!this.communications) {
      this.communications = [];
    }
  }
  setNote(name: string, note: CommunicationModel): CommunicationModel {
    note.date = new Date();
    note.name = name;
    return note;
  }

  ignoreOT?: boolean;

  hours: number; // Original number of hours planned for this appointment
  paidSubsidizedHours: number; // Number of Subsidized hours paid for this appointment, in case we need rollback
  remainingSubsidizedHours : number;

  hourlyCost: number; // Hourly rate at cost for C4G
  hourlyCharge: number; // Hourly calculated rate for CS to pay
  loRates?: LoRates; // LO rate for this appointment

  reserve: AmountTx; // Amount originally reserved
  cost: AmountTx; // C4G Cost to pay CG based on worked hours
  charge: AmountTx; // Amount to charge based on worked hours
  refund: AmountTx; // Amount refunded based on worked hours
  penaltyCost: AmountTx // Amount of penalty based on cancellation
  penaltyCharge: AmountTx // Amount of penalty based on cancellation
  penaltyC4g: AmountTx // Amount of penalty based on cancellation
  c4g: AmountTx; // Amount that goes to C4G
  paidToCg: AmountTx; // Amount paid to CG
  total_amount_cost_txt?: string;


  checkInOut?: CheckInOutModel; // Holds the actual time on target
  tempCheckIn? : Date;
  tempCheckOut? : Date;

  text: string;
  @Type(() => Date)
  endDate: Date;
  @Type(() => Date)
  startDate: Date;
  startDateText: string;
  allDay: boolean;
  // recurrenceRule: string;
  description: string;
  disabled: boolean;
  address: string;
  location: {
    longitude: number;
    latitude: number;
  };
  careseeker: string;
  caregiver: string;
  caregiverName: string;
  careGiverEmail: string;
  careSeekerEmail: string;
  careseekerName: string;
  lovedOnesPassports: LovedOnePassportModel[];
  resource: string;
  // tasks: TaskModel;

  careBookId: string;
  maxRate?: number;
  punctualTeam: string[]; // Punctual Team for CareBook Entry
  punctualTeamCGs?: {id: string, name: string}[]; // Punctual Team for CareBook Entry
  punctualTeamRefusal?: string[]; // Punctual Team for CareBook Entry
  visibility: string;

  extensionRequest?: AppointmentExtensionModel;

  created?: Date;
  updated?: Date;
}

export interface CommunicationModel {
  name: string;
  date: Date;
  message: string;
}
export interface ToPayCsModel {
  careseekerId: string
  careseekerName: string;
  bankInfo: CsBankModel;
  appointments: AppointmentModel[];
  customLineItems: CgPaymentCustomLineItemModel[];
}

export interface ToPayAppointmentsModel {
  _id: string;
  caregiverName: string;
  careGiverEmail: string;
  careSeekers: ToPayCsModel[],
}

export interface ToPayReportModel {
  _id: string;
  careseekerName: string;
  careseekerEmail: string
  careGivers: {
    caregiverName: string;
    caregiverId: string;
    appointments: AppointmentModel[];
  }[],
}

export interface PartnerReportAppointmentsModel {
  _id: string;
  careseekerName: string;
  careSeekerEmail: string;
  careGivers: {
    caregiverId: string
    caregiverName: string;
    appointments: AppointmentModel[];
  }[],
}
