<div *ngIf="!(displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkOut)"
     class="p-2 mb-2">
  <div fxLayout="row">
    <div>
      <button mat-mini-fab
              color="accent"
              class="mr-3"
              aria-label="Refresh button"
              (click)="info()">
        <mat-icon
                  >location_on</mat-icon>
      </button>
    </div>
    <div>
      <h3 class="noPads noMargin">
        {{ "bookingWith" | translate }}
        {{ name }}
      </h3>
      <h3 class="noPads noMargin">
        {{ displayedAppointment.startDate | date: 'shortTime' }} -
        {{ displayedAppointment.endDate | date: 'shortTime' }}
      </h3>
      <div>{{ displayedAppointment.address }}</div>
      <div class="button-bar mt-3"
           fxLayout="row"
           fxLayoutGap="8px">
        <button mat-raised-button
                color="accent"
                (click)="in()"
                *ngIf="!displayedAppointment.checkInOut && !inDisabled">
          Check-In
        </button>
        <mat-spinner [diameter]="20"
                     *ngIf="inDisabled"></mat-spinner>
        <button mat-raised-button
                color="accent"
                *ngIf="displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkIn"
                [disabled]="true">
          {{ displayedAppointment.checkInOut.checkIn.time | date: 'shortTime' }}
        </button>
        <button mat-raised-button
                color="warn"
                (click)="out()"
                *ngIf="displayedAppointment.checkInOut && !displayedAppointment.checkInOut.checkOut && !outDisabled">
          Check-Out
        </button>
        <mat-spinner [diameter]="20"
                     *ngIf="outDisabled"></mat-spinner>

        <button mat-raised-button
                color="warn"
                *ngIf="displayedAppointment.checkInOut && displayedAppointment.checkInOut.checkOut"
                [disabled]="true">
          {{ displayedAppointment.checkInOut.checkOut.time | date: 'shortTime' }}
        </button>
      </div>
    </div>
  </div>

</div>
