<h1 mat-dialog-title>{{"why-so-far.title" | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>{{"why-so-far.reason" | translate}}</mat-label>
    <input matInput [(ngModel)]="reason">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">{{"buttons.cancel" | translate}}</button>
  <button mat-button (click)="onConfirm()">{{"buttons.accept" | translate}}</button>
</div>
