import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module';
import { PropertiesToolbarComponent } from './properties-toolbar/properties-toolbar.component';
import { RatingComponent } from './rating/rating.component';
import { MissionComponent } from './mission/mission.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { LogoComponent } from './logo/logo.component';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig,
  LyTheme2,
  LyThemeModule,
  StyleRenderer,
} from '@alyle/ui';
import { MinimaLight } from '@alyle/ui/themes/minima';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { ImageCropperDialog } from './dialogs/image-cropper/image-cropper-dialog.component';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { ServicesSkillsAndQualificationsComponent } from './services-skills-and-qualifications/services-skills-and-qualifications.component';
import { AreaAndConditionsComponent } from './area-and-conditions/area-and-conditions.component';
import { C4gUiModule } from '@nx-c4g/c4g-ui';
import { BookingItemComponent } from './booking-item/booking-item.component';
import { CaregiverItemDetailedComponent } from './caregiver-item-detailed/caregiver-item-detailed.component';
import { InOutAppointmentComponent } from './in-out-appointment/in-out-appointment.component';
import { BookingDialogComponent } from './booking-dialog/booking-dialog.component';
import { AgendaModule } from '@nx-c4g/agenda';
import { WhySoFarDialogComponent } from './in-out-appointment/WhySoFarDialog.component';

@NgModule({
  imports: [
    C4gUiModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    LyImageCropperModule,
    LyThemeModule,
    LySliderModule,
    TranslateModule.forChild(),
    AgendaModule,
  ],
  exports: [
    C4gUiModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PipesModule,
    DirectivesModule,
    LogoComponent,
    PropertiesToolbarComponent,
    RatingComponent,
    MissionComponent,
    OurServicesComponent,
    ImageCropperDialog,
    PhotoEditorComponent,
    ServicesSkillsAndQualificationsComponent,
    AreaAndConditionsComponent,
    CaregiverItemDetailedComponent,
    InOutAppointmentComponent,
  ],
  declarations: [
    LogoComponent,
    PropertiesToolbarComponent,
    RatingComponent,
    MissionComponent,
    OurServicesComponent,
    SafeHtmlPipe,
    ConfirmationDialogComponent,
    ImageCropperDialog,
    PhotoEditorComponent,
    ServicesSkillsAndQualificationsComponent,
    AreaAndConditionsComponent,
    BookingItemComponent,
    CaregiverItemDetailedComponent,
    InOutAppointmentComponent,
    BookingDialogComponent,
    WhySoFarDialogComponent,
  ],
  providers: [
    [LyTheme2],
    [StyleRenderer],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
  ],
})
export class SharedModule {}
