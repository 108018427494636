import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { DateUtil, DurationModel, GeneralUtil } from "@nx-c4g/c4g-models";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgxMatDatepickerInputEvent } from "@angular-material-components/datetime-picker/lib/datepicker-input-base";

@Component({
  selector: 'nx-c4g-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: (control: FormControl) => {
        const _durationModel = control.value;
        if (_durationModel && _durationModel.duration <= 0) {
          return {
            mustBePositive: {
              _durationModel
            }
          }
        } else if (_durationModel.start < new Date()) {
          return {
            mustBePositive: {
              _durationModel
            }
          }
        }
        return null;
      },
      multi: true,
    }
  ]
})
export class DurationPickerComponent implements ControlValueAccessor, OnInit {

  start: Date = DateUtil.in3hrs();
  end: Date = DateUtil.addTime(this.start, 3,0,0);
  touched = false;
  @Input() timezone: string;
  @Input() address: string;
  @Output() dateChanged: EventEmitter<DurationModel> = new EventEmitter<DurationModel>();
  @ViewChild('inCtrl') inInput: ElementRef;
  @ViewChild('endCtrl') endInput: ElementRef;
  browserTimezone: any;

  get durationModel() {
    const _durationModel = new DurationModel();
    _durationModel.start = this.start;
    _durationModel.end = this.end;
    _durationModel.address = this.address;
    _durationModel.duration = DateUtil.diffMinutesNotAbs(this.end, this.start);
    return _durationModel;
  }

  set durationModel(durationModel1) {
    this.start = durationModel1.start;
    this.end = durationModel1.end;
  }

  get displayDuration() {
    return DateUtil.decimalMinutesToShortString(this.durationModel.duration);
  }

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }

  private _onChange = (_: any) => {};
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    console.log('registerOnChange');
  }

  public onTouched = (): void => undefined;

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  isMobile(): boolean {
    return GeneralUtil.isMobile();
  }

  writeValue(durationModel: DurationModel): void {
    if (durationModel) {
      this.durationModel = durationModel;
      this.dateChanged.emit(this.durationModel);
    }
  }

  markAsTouched() {
   this.onTouched();
  }

  onStartDateChanged($event: NgxMatDatepickerInputEvent<any,any>) {
        this.durationModel.end.setDate(this.durationModel.start.getDate());
    this.end = this.durationModel.end;
    this._onChange(this.durationModel);
    this.dateChanged.emit(this.durationModel);
    this.markAsTouched();
    console.log('DurationPickerComponent.onDateChanged', this.inInput.nativeElement.value, this.endInput.nativeElement.value);
    if (this.inInput.nativeElement.value.endsWith('AM') || this.inInput.nativeElement.value.endsWith('PM')) {
      this.endInput.nativeElement.value = this.end.toLocaleString();
    } else {
      this.endInput.nativeElement.value = `${this.end.getMonth() + 1}/${this.end.getDate()}/${this.end.getFullYear()}, ${this.end.getHours().toString().padStart(2, '0')}:${this.end.getMinutes().toString().padStart(2, '0')}:00`;
    }
    this.cd.detectChanges();

    console.log('DurationPickerComponent.onDateChanged');
  }


  onEndDateChanged($event: NgxMatDatepickerInputEvent<any,any>) {
       this._onChange(this.durationModel);
     this.dateChanged.emit(this.durationModel);
     this.markAsTouched();

     console.log('DurationPickerComponent.onDateChanged');
   }


  isNegative() {
    return this.durationModel.duration <= 0;
  }

  negative() {
    return this.durationModel.duration > 0 ? 'good' : 'bad';
  }

  lessThanToday(date: Date) {
    return date < new Date();
  }

  ngOnInit(): void {
    this.browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
