import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'nx-c4g-why-so-far-dialog',
  templateUrl: './WhySoFarDialog.component.html',
  styleUrl: './WhySoFarDialog.component.scss',
})
export class WhySoFarDialogComponent {
  reason = '';

  constructor(public dialogRef: MatDialogRef<WhySoFarDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close(null);
  }

  onConfirm(): void {
    this.dialogRef.close(this.reason);
  }
}
